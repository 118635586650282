import React from 'react';

export default function Below() {
  return (
    <div id="about" className="below">
      <div className="below-container">
        <h2>Do Not Touch Your Face is a tool that teaches you to avoid touching your face using your webcam.</h2>

        <ul>
          <li>You train a machine learning algorithm with your webcam</li>
          <li>The website alerts you when you're touching your face</li>
          <li>Over time, you learn to stop touching your face</li>
        </ul>

        <h2>FAQ</h2>

        <p>
          <strong>How does this work?</strong>
          Using your webcam, you train a machine learning algorithm (specifically <a href="https://www.tensorflow.org/js" target="_blank" rel="noopener noreferrer">Tensorflow.js</a>) to recognize you touching your face and not touching your face. Once it's trained, it watches and alerts you when you touch your face.
        </p>

        <p>
          <strong>Why shouldn't I touch my face?</strong>
          The <a href="https://www.cdc.gov/coronavirus/2019-ncov/about/prevention-treatment.html" target="_blank" rel="noopener noreferrer">CDC recommends</a> not touching your face as one action you can take to prevent getting COVID-19. Other things you should do: stay home if you're sick and avoid contact with other sick people. But you probably knew that already.
        </p>

        <p>
          <strong>The alerts aren't working!</strong>
          Try refreshing the page and trying again. Every time you reload the page, the algorithm retrains itself.
        </p>

        <p>
          <strong>Do you keep my information?</strong>
          Nope. This entire site runs locally—all the calculations from your webcam and alerts are done on your computer and are never sent over the internet.
        </p>

        <p>
          <strong>Will this stop me from getting COVID-19?</strong>
          Not for sure, but it might help.
        </p>

        <p>
          <strong>Who made this?</strong>
          This was made with love and fear by <a href="https://bod.ge" target="_blank" rel="noopener noreferrer">Mike Bodge</a>, <a href="https://brianmoore.com/" target="_blank" rel="noopener noreferrer">Brian Moore</a>, and <a href="http://isaacblankensmith.com/" target="_blank" rel="noopener noreferrer">Isaac Blankensmith</a>. Be safe out there.
        </p>
      </div>
    </div>
  );
}
