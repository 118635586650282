import React from 'react';

export default function Mobile() {
  return (
    <div className="mobile">
      <div>
        <h1>Come back on desktop</h1>
        Do Not Touch Your Face is only compatible with a full-on computer, as it’s meant to alert you while you’re sitting at your desk.
      </div>
    </div>
  );
}
