import React, {useEffect, useRef, useState} from 'react';
import {Howl} from 'howler';

import {initNotifications, notify} from './vendor/Notification';
import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';
import * as knnClassifier from '@tensorflow-models/knn-classifier';

import Favicon from 'react-favicon';

import BelowTheFold from './BelowTheFold';

import soundURL from './img/no.mp3';
import happy from './img/happy.svg';
import sad from './img/sad.svg';
import bubble from './img/bubble.svg';
import cameraImg from './img/camera.svg';
import mutedImg from './img/volume-off.svg';
import notMutedImg from './img/volume-on.svg';

import noHands from './img/hands-off.gif';
import yesHands from './img/hands-on.gif';

const TRAINING_TICKS = 50;
const THRESHOLD = 0.9;

export default function Train() {
  const video = useRef();
  const classifier = useRef();
  const mobilenetModule = useRef();
  const bar = useRef();
  const countdown = useRef();
  const last = useRef(false);
  const mutedRef = useRef(false);

  const [loaded, setLoaded] = useState(false);
  const [step, setStep] = useState(0);
  const [training, setTraining] = useState(false);
  const [counting, setCountdown] = useState(false);
  const [ai, setAI] = useState(false);
  const [trainingDone, setTrainingDone] = useState(false);
  const [touching, setUserTouching] = useState(false);
  const [muted, setMuted] = useState(false);

  const sound = new Howl({
    src: [soundURL]
  });

  const init = async function() {
    if(loaded) return;

    // activate camera
    await setupWebcam();

    setAI(true);
    // Create the classifier.
    classifier.current = knnClassifier.create();

    // Load mobilenet.
    mobilenetModule.current = await mobilenet.load();

    // init notifications
    initNotifications({cooldown: 3000});

    setLoaded(true);
  }

  const setupWebcam = async () => {
    return new Promise((resolve, reject) => {
      const navigatorAny = navigator;
      navigator.getUserMedia = navigator.getUserMedia ||
        navigatorAny.webkitGetUserMedia || navigatorAny.mozGetUserMedia ||
        navigatorAny.msGetUserMedia;
      if(navigator.getUserMedia) {
        navigator.getUserMedia({video: true},
          stream => {
            video.current.srcObject = stream;
            video.current.addEventListener('loadeddata', () => {
              if(!loaded) {
                resolve();
              }
            }, false);
          },
          error => reject());
      }
    });
  }

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const trainCountdown = async (n) => {
    setCountdown(true);
    bar.current.style.width = '0%';
    countdown.current.className = 'countdown item-active-flex countdown3';
    await sleep(1000);
    countdown.current.className = 'countdown item-active-flex countdown2';
    await sleep(1000);
    countdown.current.className = 'countdown item-active-flex countdown1';
    await sleep(1000);
    setCountdown(false);
    setTraining(true);
    if(n === 0){
      train0();
    } else {
      train1();
    }

  }

  const train0 = async () => {
    for( let x = 0 ; x < TRAINING_TICKS ; x++) {
      await train(0);
      setBar(x);
    }
    setStep(1);
    setTraining(false);
  }

  const train1 = async () => {
    for( let x = 0 ; x < TRAINING_TICKS ; x++) {
      await train(1);
      setBar(x);
    }
    setStep(2);
    setTraining(false);
  }

  const setBar = (x) => {
    bar.current.style.width = parseInt(x / TRAINING_TICKS * 100) + '%'
  };

  const train = async (c) => {
    return new Promise(async (resolve) => {
      const activation = mobilenetModule.current.infer(video.current, classifier);
      classifier.current.addExample(activation, c);

      await tf.nextFrame();
      await sleep(100);
      resolve();
    });
  }

  const doDetection = async() => {
    if(classifier.current.getNumClasses() > 0) {
      const activation = mobilenetModule.current.infer(video.current, classifier);
      const result = await classifier.current.predictClass(activation);

      if(result.classIndex === 1 && result.confidences[result.classIndex] > THRESHOLD) {
        setTouching(true);
      } else {
        setTouching(false);
      }
    }

    //await tf.nextFrame();
    setTimeout(() => doDetection());
  };

  const setTouching = (tf) => {
    if(tf) {
      setUserTouching(true);

      document.title='You touched your face!';

      if(tf !== last.current && !mutedRef.current) {
        sound.play();
      }

      notify('Do Not Touch Your Face', {body: 'You touched your face.'});
    } else {
      setUserTouching(false);
      document.title="Do Not Touch Your Face";
    }

    last.current = tf;
  }


  useEffect(()=>{
    mutedRef.current = muted;
  }, [muted]);



  init();

  return (
    <>
      {touching ? <Favicon url={process.env.PUBLIC_URL + 'favicon-no.ico'} /> : <Favicon url={process.env.PUBLIC_URL + 'favicon.ico'} />}
      <div className={touching ? "all all-touching" : "all"}>
        <div className="area-top">
          <div className="big-text">Do Not</div>
        </div>

        <div className="area-right">
          <div className="img-wrapper img-wrapper-top">
            <img src={happy} alt="Happy Face" className="happy" />
            <img src={sad} alt="Sad Face" className="sad" />
          </div>

          <div className="img-wrapper img-wrapper-bottom">
            <img src={happy} alt="Happy Face" className="happy" />
            <img src={sad} alt="Sad Face" className="sad" />
          </div>

          <div className="big-text">Touch</div>
        </div>

        <div className="area-bottom">
          <div className="big-text">Your</div>
        </div>

        <div className="area-left">
          <div className="img-wrapper img-wrapper-top">
            <img src={bubble} alt="Teach yourself not to touch your face" className="bubble" />
          </div>

          <div className="big-text">Face</div>

          <div className="img-wrapper img-wrapper-bottom">
            <img src={happy} alt="Happy Face" className="happy" />
            <img src={sad} alt="Sad Face" className="sad" />
          </div>
        </div>
        <div className="container">
          <div className="center center-pad">
            <div>
              <div className="detector">
                <video ref={video} autoPlay muted playsInline width="640" height="480"></video>
                {step === 0 && loaded ? <img src={noHands} alt="No hands" className="hand-gif" /> : null}
                {step === 1 && loaded ? <img src={yesHands} alt="Yes hands" className="hand-gif" /> : null}

                {touching ? <div className="detector-no">NO!</div> : null}

                <div className={trainingDone ? "training-ui training-ui-hide" : (training ? "training-ui training-ui-red" : "training-ui")}>
                  <div className="left">
                    {!loaded && !ai ? 'Waiting for webcam access...' : null}
                    {!loaded && ai ? 'Activating face touching AI...' : null}
                    {step === 0 && loaded && !training ? <span>1. Take a video <u>not touching</u> your face</span> : null }
                    {step === 0 && loaded && training ? <span>Recording... <u>do not touch</u> your face</span> : null }
                    {step === 1 && loaded && !training ? <span>2. Take a video <u>continuously touching</u> your face (with clean hands)</span> : null }
                    {step === 1 && loaded && training ? <span>Don't take your hands off your face until it's done!</span> : null }
                  </div>
                  <div className="right">
                    {step === 0 && loaded && !training && !counting ? <button onClick={() => trainCountdown(0)}>Take Video</button>: null }
                    {step === 1 && loaded && !training && !counting ? <button onClick={() => trainCountdown(1)}>Take Video</button>: null }

                    <div className={training ? 'bar item-active' : 'bar'}>
                      <div ref={bar}></div>
                    </div>

                    <div ref={countdown} className={counting ? 'countdown item-active-flex' : 'countdown'}>
                      <div>3</div>
                      <div>2</div>
                      <div>1</div>
                      <div><img src={cameraImg} alt="Camera" /></div>
                    </div>
                  </div>

                  {step === 2 && loaded && !training && !counting ? <div className="training-ui-center"><button onClick={() => { setTrainingDone(true); doDetection();}}>Ready!</button></div> : null }

                </div>
              </div>
            </div>
          </div>


          <div className="about-btn">
            <a href="#about">About</a>
          </div>

          <div className="mute-btn">
            <button onClick={() => setMuted(!muted)}>
              {muted ? <img src={mutedImg} alt="Sound Off" /> : <img src={notMutedImg} alt="Sound On" /> }
            </button>
          </div>

        </div>
      </div>
      <BelowTheFold />
    </>
  )
}
